<template>
  <div
    class="train-box m_tb_20 flex_row"
    v-loading="loading">
    <div
      class="train-item-box br_6 m_b_10 pointer"
      v-for="data in resourceData"
      :key="data.id"
      @click="toDetail_click(data)">
      <div class="cover-box">
        <img
          class="br_6"
          :src="data.coverUrl"
          :alt="data.title" />
        <div
          :class="`tag c_fff ${data.state}`"
          :style="`background-color:${tagColor[data.state]}`">
          {{
            data.state == 'waiting'
              ? '未开始'
              : data.state == 'underway'
              ? '进行中'
              : '已完成'
          }}
        </div>
      </div>
      <div class="title-box fs_18 fw_bold text_hidden">{{ data.title }}</div>
    </div>
    <div
      class="train-item-box"
      v-show="resourceData.length % 4 != 0"
      v-for="(i, index) in 4 - (resourceData.length % 4)"
      :key="index"></div>
    <div class="paging">
      <el-pagination
        @current-change="current_change"
        :page-size="params.size"
        :background="true"
        layout="total, prev, pager, next"
        :total="params.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import { sessionGet } from '@/utils/local';
import img from '@/assets/image/5g.jpg';
import { getPartyResources } from '@/api/party/index';
export default {
  name: 'TrainingLearning',
  data() {
    return {
      img,
      tagColor: {
        waiting: '#FF8D4D',
        underway: '#60B1FC',
        finished: '#C2C2C2',
      },
      params: {
        contentId: null,
        current: 1,
        size: 8,
        total: 0,
      },
      resourceData: [],
      loading: false,
    };
  },
  mounted() {
    let catalogues = sessionGet('SystemMenus');
    catalogues.forEach((item) => {
      if (item.name == '学习培训') {
        this.params.contentId = item.id;
      }
    });
    this.getResourceData();
  },
  methods: {
    async getResourceData() {
      this.loading = true;
      await getPartyResources(this.params).then((res) => {
        let data = res.data.records;
        data.forEach((item) => {
          let now = new Date().getTime();
          let start = new Date(item.startTime).getTime();
          let end = new Date(item.endTime).getTime();
          if (start > now) {
            item.state = 'waiting';
          } else if (start < now && now < end) {
            item.state = 'underway';
          } else {
            item.state = 'finished';
          }
        });
        this.resourceData = data;
        this.params.total = res.data.total;
      });

      this.loading = false;
    },
    toDetail_click(data) {
      this.$router.push({
        path: '/party/trainingDetail',
        query: { id: data.id },
      });
    },
    current_change(current) {
      this.params.current = current;
      this.getResourceData();
    },
  },
};
</script>

<style scoped>
.train-box {
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 200px;
}
.train-item-box {
  width: 290px;
  height: 193px;
}
.cover-box {
  width: 100%;
  height: 167px;
  position: relative;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tag {
  width: 66px;
  height: 24px;
  position: absolute;
  display: block;
  top: 6px;
  right: -3px;
  text-align: center;
  line-height: 24px;
  border-radius: 4px 4px 0 4px;
  z-index: 999;
}
.tag::after {
  content: '';
  display: block;
  width: 3px;
  height: 2px;
  position: absolute;
  right: 0px;
  bottom: -2px;
  /* transform: rotate(40deg); */
  z-index: 0;
  border-radius: 0 0 10px 0;
}

.waiting::after {
  content: '';
  background-color: #ff8d4d;
}
.underway::after {
  content: '';
  background-color: #60b1fc;
}
.finished::after {
  content: '';
  background-color: #c2c2c2;
}
.paging {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #dd2622;
}
</style>
